<template>
    <div class="cookies">
        <div class="legal-title">
            Politique Cookies
        </div>

        <HCard
            free
            class="legal-block"
        >
            <div class="block-text">

                <div class="block-title">Champ d’application</div>

                <p>HDC Santé, ci-après « nous » ou « la Société », éditeur du présent site, ci-après « le Site », traite vos données
                    personnelles en qualité de responsable de traitement tout au long de votre navigation sur le Site.</p>

                <p>Le Site utilise des cookies, traceurs et autres technologies de même nature, déposés et/ou lus par nos soins, ou
                par des tiers, lors de votre consultation. Pour simplifier, toutes ces technologies sont désignées ci-après par le
                terme générique « Cookies ».</p>

                <p>La présente politique pour objectif de vous informer sur le fonctionnement et l’utilisation des Cookies sur le Site.
                Ce périmètre intègre l’ensemble des services rattachés au Site ainsi que l’ensemble de ses sous-domaines. La
                présente politique est complémentaire à la politique de confidentialité de la Société.</p>

                <div class="block-title">Qu’est-ce qu’un Cookie ?</div>

                <p>Le terme de Cookies est à prendre au sens large et couvre l'ensemble des traceurs déposés et/ou lus, par exemple,
                lors de la consultation d'un site internet ou d'une application mobile, ou lors de l’activation ou préalablement à
                l’activation d'un chatbot. Par commodité, nous utilisons le terme de « Cookies » qui recouvre l'ensemble des
                technologies ayant pour effet de lire ou écrire des données dans le terminal de l’utilisateur. Un Cookie ou un traceur
                est déposé par votre navigateur internet (ex : Internet Explorer, Firefox, Safari ou encore Google Chrome, ...) sur
                un espace dédié du disque dur de votre terminal, par le serveur du Site ou d'une application mobile que vous
                visitez ou consultez.</p>

                <p>Les informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs des tierces parties
                concernées lors d’une visite ultérieure.</p>

                <div class="block-title">Quels sont les types de Cookies utilisés sur le Site ?</div>

                <div class="block-subtitle block-li">• Cookies techniques et nécessaires au fonctionnement du Site</div>

                <p>Ces Cookies sont nécessaires au bon fonctionnement du Site pour la prise en compte de vos préférences en tant
                qu’utilisateur. A ce titre, leur collecte ne requiert pas de consentement de votre part. En plaçant des Cookies
                fonctionnels, nous souhaitons faciliter la navigation sur le Site. Ces Cookies permettent d’assurer la sécurité et la
                performance du Site. Ils contribuent à rendre le Site utilisable en activant des fonctions de base comme la
                navigation de page et l'accès aux zones sécurisées du Site. Ce dernier ne peut pas fonctionner correctement sans
                ces Cookies. Ils sont strictement nécessaires à son fonctionnement et ne peuvent pas être désactivés sans risquer
                de ne plus pouvoir accéder au Site ou à certains services du Site. Nous utilisons ces Cookies dans notre intérêt
                légitime. Ils expirent automatiquement lorsque vous quittez notre Site.</p>

                <div class="block-subtitle block-li">• Cookies de mesure d’audience soumis au recueil de votre consentement</div>

                <p>Les Cookies de mesure d’audience utilisés sur le Site permettent à la fois de mesurer l’audience et de suivre la
                navigation. Dans ce cas, ils sont soumis à votre consentement.</p>

                <p>Sous réserve de votre consentement, certains Cookies de mesure d’audience nous fournissent des statistiques
                complémentaires, sur l’utilisation du Site afin notamment d'améliorer votre expérience de navigation.</p>

                <div class="block-subtitle block-li">• Cookies de mesure d’audience exemptés du recueil de votre consentement</div>

                <p>L’usage de certains Cookies de mesure d’audience sur le Site est dispensé du recueil de votre consentement,
                conformément à l’article 82 de la loi Informatique et Libertés ainsi qu’aux dispositions de l’article 5 de la délibération
                CNIL n°2020-091 du 17 Septembre 2020.</p>

                <p>Ces Cookies sont strictement limités à la seule mesure d’audience sur le Site pour le compte exclusif de la Société.
                Ils servent uniquement à produire des données statistiques anonymes. Ils ne permettent pas le suivi global de
                votre navigation lorsque vous utilisez différentes applications ou naviguez sur différents sites web. Ils ne permettent
                pas le recoupement des données avec d’autres traitements ou à ce que les données soient transmises à des tiers.</p>

                <p>Ces cookies nous aident, par la collecte et la communication d'informations, à comprendre comment les utilisateurs
                interagissent avec le Site ou les applications mobiles. Ils peuvent servir à mesurer la fréquentation et l’utilisation
                du Site ou des applications. Ainsi, ils permettent de mener des études et nous aident à améliorer l’intérêt et
                l’ergonomie de nos services. Ces Cookies de mesure d’audience sont utilisés dans notre intérêt légitime, afin
                d’analyser les pages du Site consultées et nous permettent d'établir des statistiques anonymes de sa fréquentation.</p>

                <div class="block-title">Comment pouvez-vous gérer votre consentement ?</div>

                <p>Lors de votre première visite sur le Site, une fenêtre contextuelle est affichée comprenant une information sur les Cookies.</p>

                <p>
                    • En cliquant sur « Tout accepter », vous nous autorisez à déposer l’ensemble des Cookies soumis à votre consentement sur votre terminal.<br />
                    • En cliquant sur « Tout refuser », vous nous interdisez de déposer des Cookies soumis à votre consentement sur votre terminal.
                </p>

                <p>Pour les cookies soumis à votre consentement, vos choix de dépôt, seront conservés pour une durée de treize (13) mois. A cette échéance, vous serez à nouveau sollicité pour exprimer vos choix. (Les données collectées grâce au cookie sont, elles, conservées 25 mois.)</p>

                <p>Vous pouvez également modifier vos choix en cliquant 
                    <button
                        role="button"
                        @click="showCookieConsentPreferences"
                        class="text-primary text-decoration-underline"
                    >
                    ici
                    </button>.
                </p>

                <div class="block-title">Comment pouvez-vous exercer vos droits ?</div>

                <p>Pour exercer vos droits ou contacter le Délégué à la Protection des données, vous pouvez nous adresser une
                demande à :</p>

                <p>
                    • dpo@happytal.com<br />
                    • Happytal – Délégué à la Protection des données – 59 rue Pernety – 75014 Paris
                </p>

                <p>En cas de difficulté dans la gestion de vos données personnelles, vous avez le droit d’introduire une réclamation
                auprès la Commission Nationale de l’Informatique et des Libertés (CNIL).</p>
            </div>
        </HCard>
    </div>
</template>

<script>
  import {
    HCard
  } from '@happytal/bo-ui-library'
  import * as CookieConsent from 'vanilla-cookieconsent'

  export default {
    components: {
      HCard
    },
    methods: {
        showCookieConsentPreferences() {
            CookieConsent.showPreferences()
        }
    }
  }
</script>
